import { api } from './api.ts'
import { ProductAttribute, ProductImage } from '../types/product.ts'
import Config from '../helpers/Config.js'
import { unminifyJSON } from '../helpers/CommonHelper'
import { cartActions } from '../stateManagment/features/cartSlice.ts'

export type CartProduct = {
    productId: string;
    productName?: string;
    colorName?: string;
    sizeShortName?: string;
    price?: number;
    embroidery?: any[];
    embroideryPrice?: number;
    discountedPrice?: number;
    orderMaxQuantity?: number;
    lineItemId?: string;
    productSelectedAttributes?: ProductAttribute[];
    quantity: number;
    defaultImage?: string;
    HexCode: string;
    EmbPreviewBaseUrl: string;
    customSizingText: string;
    DepartmentId: string;
    ColorLongName: string;
    EmbTemplateId: number;
    images?: ProductImage[];
    availabilityMessage: string;
    productPrice: number;
    SelectedEmployee: any;
    regularPrice: number;
    skuOnSaleOrClearance: boolean;
    skuOnSale: boolean;
    skuOnClearance: boolean;
    isOrderable: boolean;
    itemClass: string;
    taxRate: number;
}

export type PaymentCard = {
    type: string;
}

export type PaymentInstrument = {
    paymentInstrumentId: string;
    paymentMethodId: string;
    amount: number;
    payrollMaxDeductions: number;
    paymentCard: PaymentCard;
    gcNumber: string;
}

export type Cart = {
    id: string;
    totalCartItems?: number;
    cartSubTotal: number;
    orderTotal: number;
    shippingSubTotal: number;
    taxTotal: number;
    savingsTotal: number;
    products: CartProduct[];
    paymentInstruments: PaymentInstrument[];
    shippingMethod: any;
    adjustedTax: number;
    adjustedTotal: number;
    paymentAmounts: any;
}

const cartMutationResponse = (response: any, meta: any, arg: any): Cart => {
    const { id, cartSubTotal, orderTotal, shippingSubTotal, adjustedTax, adjustedTotal, savingsTotal, taxTotal, productsData: products, shipments } = response.data;
    var selectedPaymentMethod = arg?.additionalInfo?.selectedPaymentMethod ?? '';
    const transformedResponse = {
        id,
        cartSubTotal,
        orderTotal,
        shippingSubTotal,
        savingsTotal,
        taxTotal,
        adjustedTax: (response?.data?.paymentAmounts[selectedPaymentMethod] ?? orderTotal) - shippingSubTotal - cartSubTotal,
        adjustedTotal: response?.data?.paymentAmounts[selectedPaymentMethod] ?? orderTotal,
        products: products.map((item: any): CartProduct => {
            try {
                item.EmbroideryString = JSON.parse(unminifyJSON(item.EmbroideryString));
            } catch (e) {
                item.EmbroideryString = [];
            }
            if (!item.ProductImagesJson) {
                item.ProductImagesJson = [{
                    AttachmentURL: '/no-image.png',
                    AttachmentName: 'No Image',
                    AttachmentID: 0,
                    ProductID: item.ProductId,
                    ColorID: item.ColorId,
                }];
            }
            return {
                productId: item.ProductId,
                embroidery: item.EmbroideryString,
                productName: item.ProductName,
                colorName: item.ColorName,
                price: item.Price,
                sizeShortName: item.SizeShortName,
                embroideryPrice: item.EmbroideryPrice,
                discountedPrice: item.DiscountedPrice,
                productPrice: (item.DiscountedPrice > 0 ? item.DiscountedPrice : item.Price) - (item.EmbroideryPrice * item.Quantity),
                orderMaxQuantity: item.OrderMaximumQuantity,
                lineItemId: item.LineItmeId,
                quantity: item.Quantity,
                EmbPreviewBaseUrl: item.EmbPreviewBaseUrl,
                customSizingText: item.CustomSizingText,
                SelectedEmployee: item.SelectedEmployee,
                DepartmentId: item.CategoryId,
                ColorLongName: item.ColorLongName,
                HexCode: item.HexCode,
                EmbTemplateId: item.EmbTemplateId,
                availabilityMessage: item.AvailabilityMessage,
                regularPrice: item.RegularPrice,
                skuOnSaleOrClearance: item.SkuOnSaleOrClearance,
                skuOnSale: item.SkuOnSale,
                skuOnClearance: item.SkuOnClearance,
                isOrderable: item.IsOrderable,
                itemClass: item.ItemClass,
                images: item.ProductImagesJson?.map((image: any) => ({
                    id: image.AttachmentID,
                    name: image.AttachmentName,
                    url: image.AttachmentURL,
                    productId: image.ProductID,
                    colorId: image.ColorID,
                })),
                taxRate: item.TaxRate
            } || []
        }),
        paymentAmounts: response.data.paymentAmounts,
        paymentInstruments: response.data.paymentInstruments.map((item: any) => ({
            paymentInstrumentId: item.payment_instrument_id,
            paymentMethodId: item.payment_method_id,
            gcNumber: item.c_giftCardNumber + item.c_giftCardPin,
            amount: item.amount,
            availableAmount: item.c_giftCardAvailableAmount,
            payrollMaxDeductions: item.c_B2B_PayrollDeductionMaxDeductions,
            paymentCard: {
                type: item.payment_card?.card_type,
            }
        })),
        shippingMethod: shipments?.length > 0 && shipments[0].shipping_method ?
            { ...shipments[0].shipping_method, shippingTax: shipments[0].adjusted_shipping_total_tax } : { id: '', name: '' },
    }
    return transformedResponse
}
// Define a service using a base URL and expected endpoints
export const cartApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCartData: build.query<Cart, any>({
            query: (removeBadProducts) => {

                const params = {
                    requestParameters: {
                        recordValueJson: "[]",
                        RemoveBadProducts: !!removeBadProducts
                    }
                };
                return {
                    method: 'POST',
                    body: params,
                    url: Config.END_POINT_NAMES.GET_CART_DETAIL
                }
            },
            transformResponse: cartMutationResponse,
            async onQueryStarted(ref, { dispatch, queryFulfilled }) {
                try {
                    const { data: cartData } = await queryFulfilled
                    dispatch(cartActions.setCart(cartData))
                } catch { }
            },
            providesTags: ['Cart']
        }),
        getGcBalance: build.mutation<Cart, any>({
            query: (gcNumber: any) => {

                const params = {
                    requestParameters: {
                        recordValueJson: "[]",
                        GiftCardNumber: gcNumber
                    }
                };
                return {
                    method: 'POST',
                    body: params,
                    url: Config.END_POINT_NAMES.GET_GC_BALANCE
                }
            }
        }),
        addProduct: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        ProductId: payload.productId,
                        Quantity: payload.quantity,
                        ProductSelectedAttributes: payload.productSelectedAttributes,
                        SelectedEmployee: payload.selectedEmployee,
                        DefaultImage: payload.defaultImage,
                        CategoryId: payload.CategoryId,
                        HexCode: payload.HexCode,
                        ColorLongName: payload.ColorLongName,
                        SelectedEmbId: payload.SelectedEmbTemplateId ?? -1,
                        AvailabilityMessage: payload.availabilityMessage,
                        lineItemId: payload.lineItemIdFound,
                        ItemClass: payload.itemClass
                    }
                }
                return {
                    url: payload.lineItemIdFound ? Config.END_POINT_NAMES.EDIT_LINE_ITEM_PRODUCT : Config.END_POINT_NAMES.ADD_TO_CART,
                    method: 'POST',
                    body: params
                }
            },
            transformResponse: cartMutationResponse,
            invalidatesTags: ['ShippingMethods'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled
                    const patchResult = dispatch(
                        cartApi.util.upsertQueryData('getCartData', _, cartData)
                    )
                } catch { }
            }
        }),
        addGcToCart: build.mutation<any, any>({
            query: (gcNumber: any) => {
                const params = {
                    requestParameters: {
                        GiftCardNumber: gcNumber
                    }
                }
                return {
                    url: Config.END_POINT_NAMES.ADD_GC_TO_BASKET,
                    method: 'POST',
                    body: params
                }
            },
            transformResponse: cartMutationResponse,
            invalidatesTags: ['Cart'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled
                    const patchResult = dispatch(
                        cartApi.util.upsertQueryData('getCartData', _, cartData)
                    )
                } catch { }
            }
        }),
        changeQuantity: build.mutation<Cart, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        ProductID: payload.productId,
                        ProductItemId: payload.lineItemID,
                        TargetQuantity: payload.newLineQuantity,
                        EmbConfig: payload.embroideryString,
                        EmbPrice: payload.embroideryPrice,
                        ItemClass: payload.itemClass,
                        DepartmentId : payload.DepartmentId,
                        SizingTemplate : payload.sizingTemplate
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.CHANGE_LINE_QUANTITY,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: cartMutationResponse,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled
                    dispatch(
                        cartApi.util.updateQueryData('getCartData', _, (draft) => {
                            Object.assign(draft, cartData)
                        })
                    )
                } catch { }
            }
        }),
        removeCartItem: build.mutation<Cart, any>({
            query: (payload: any) => {
                let params = {
                    requestParameters: {
                        ProductItemId: payload
                    },
                };

                return {
                    url: Config.END_POINT_NAMES.REMOVE_FROM_CART,
                    method: 'POST',
                    body: params
                }
            },
            transformResponse: cartMutationResponse,
            invalidatesTags: ['Cart'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled
                    dispatch(
                        cartApi.util.updateQueryData('getCartData', _, (draft) => {
                            Object.assign(draft, cartData)
                        })
                    )
                } catch { }
            }
        }),
        removeGCFromCart: build.mutation<Cart, any>({
            query: (pi: any) => {
                let params = {
                    requestParameters: {
                        PaymentInstrumentId: pi
                    },
                };

                return {
                    url: Config.END_POINT_NAMES.REMOVE_GC_FROM_BASKET,
                    method: 'POST',
                    body: params
                }
            },
            transformResponse: cartMutationResponse,
            invalidatesTags: ['Cart'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled
                    dispatch(
                        cartApi.util.updateQueryData('getCartData', _, (draft) => {
                            Object.assign(draft, cartData)
                        })
                    )
                } catch { }
            }
        }),
        editLineProduct: build.mutation<Cart, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        ProductId: payload.productId,
                        Quantity: payload.quantity,
                        ProductSelectedAttributes: payload.productSelectedAttributes,
                        DefaultImage: payload.defaultImage,
                        CategoryId: payload.CategoryId,
                        HexCode: payload.HexCode,
                        ColorLongName: payload.ColorLongName,
                        SelectedEmbId: payload.SelectedEmbTemplateId ?? -1,
                        AvailabilityMessage: payload.availabilityMessage,
                        lineItemId: payload.lineItemId,
                        ItemClass: payload.itemClass,
                        SelectedEmployee: payload.selectedEmployee
                    }
                }

                return {
                    url: Config.END_POINT_NAMES.EDIT_LINE_ITEM_PRODUCT,
                    method: 'POST',
                    body: params
                }
            },
            transformResponse: cartMutationResponse,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled
                    dispatch(
                        cartApi.util.updateQueryData('getCartData', _, (draft) => {
                            Object.assign(draft, cartData)
                        })
                    )
                } catch { }
            }
        }),
        calculateShipping: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        CustBillingAddress: {
                            full_name: payload.billingAddress?.fullName || '',
                            first_name: payload.billingAddress?.firstName || '',
                            last_name: payload.billingAddress?.lastName || '',
                            address1: payload.billingAddress?.address1 || '',
                            address2: payload.billingAddress?.address2 || '',
                            city: payload.billingAddress?.city || '',
                            state_code: payload.billingAddress?.stateCode || '',
                            postal_code: payload.billingAddress?.postalCode || '',
                            country_code: payload.billingAddress?.countryCode || '',
                            phone: payload.billingAddress?.phone || '',
                        },
                        CustShippingAddress: {
                            full_name: payload.shippingAddress?.fullName || '',
                            first_name: payload.shippingAddress?.firstName || '',
                            last_name: payload.shippingAddress?.lastName || '',
                            address1: payload.shippingAddress?.address1 || '',
                            address2: payload.shippingAddress?.address2 || '',
                            city: payload.shippingAddress?.city || '',
                            state_code: payload.shippingAddress?.stateCode || '',
                            postal_code: payload.shippingAddress?.postalCode || '',
                            country_code: payload.shippingAddress?.countryCode || '',
                            phone: payload.shippingAddress?.phone || '',
                        },
                        ShippingID: payload.shippingId,
                        AdditionalInfo: payload.additionalInfo
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.CALCULATE_SHIPPING,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: cartMutationResponse,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    _ = null;
                    const { data: cartData } = await queryFulfilled;
                    dispatch(
                        cartApi.util.updateQueryData('getCartData', _, (draft) => {
                            if (!draft)
                                return;
                            Object.assign(draft, cartData)
                        })
                    )
                    dispatch(cartActions.setCart(cartData))
                } catch { }
            },
            providesTags: ['Cart']
        }),
    })
})

export const {
    useAddProductMutation,
    useChangeQuantityMutation,
    useGetCartDataQuery,
    useLazyGetCartDataQuery,
    useRemoveCartItemMutation,
    useEditLineProductMutation,
    useCalculateShippingQuery,
    useGetGcBalanceMutation,
    useAddGcToCartMutation,
    useRemoveGCFromCartMutation
} = cartApi
