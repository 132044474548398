import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { showErrorMsg, showSuccessMsg, validateAnyFormField } from '../../../helpers/ValidationHelper';
import { FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';

const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [CnfEmailAddress, setCnfEmailAddress] = useState('');
    const [Password, setPassword] = useState('');
    const [CnfPassword, setCnfPassword] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [Company, setCompany] = useState('');
    const [AddressLineOne, setAddressLineOne] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [StateProvinceId, setStateProvinceId] = useState('');
    const [CityId, setCityId] = useState('');
    const [PostalCode, setPostalCode] = useState('');
    const [CountryID, setCountryID] = useState('');
    const [CountriesList, setCountriesList] = useState([]);
    const [StatesProvincesList, setStatesProvincesList] = useState([]);
    const [CitiesList, setCitiesList] = useState([]);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [showErrorResponse, setShowErrorResponse] = useState(false);
    const [showSuccessResponse, setShowSuccessResponse] = useState(false);
    const [ErrorResponse, setErrorResponse] = useState('');
    const [SuccessResponse, setSuccessResponse] = useState('');
    const [Pass, setPassToggle] = useState(true);
    const [CnfPass, setCnfPassToggle] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [feedbackErrors, setFeedbackErrors] = useState({})

    const HandleStateProvinceChagne = async (value) => {
        if (value != undefined) {
            await setStateProvinceId(value);

            //--load city data when state changed
            await LoadCitiesData(value);
        }

    }
    

    const removeFeedbackError = (e) => {
        delete feedbackErrors[e];
        setFeedbackErrors({ ...feedbackErrors });
    }

    const HandleCountryChange = async (value) => {
        if (value != undefined) {
            await setCountryID(value);

            //--load state province data
            await LoadStateProvinceData(value);
        }

    }

    const LoadCitiesData = async (StateValue) => {
        const headersCity = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramCity = {
            requestParameters: {
                StateProvinceId: StateValue ?? StateProvinceId,
                recordValueJson: "[]",
            },
        };

        //--Get cities list
        const responseCities = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CITIES_LIST'], null, paramCity, headersCity, "POST", true);
        if (responseCities != null && responseCities.data != null) {
            await setCitiesList(JSON.parse(responseCities.data.data));

        }
    }

    const LoadStateProvinceData = async (CountryValue) => {
        const headersStateProvince = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramStateProvince = {
            requestParameters: {
                CountryId: CountryValue ?? CountryID,
                recordValueJson: "[]",
            },
        };

        //--Get state province list
        const responseStatesProvince = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_STATES_PROVINCES_LIST'], null, paramStateProvince, headersStateProvince, "POST", true);
        if (responseStatesProvince != null && responseStatesProvince.data != null) {
            await setStatesProvincesList(JSON.parse(responseStatesProvince.data.data));

        }
    }
    const handleSignupForm = async (event) => {

        dispatch(rootAction.commonAction.setLoading(true));

        event.preventDefault();

        try {

            setIsProcessing(true);
            //Clean previous errors
            Object.keys(feedbackErrors).forEach(key => {
                delete feedbackErrors[key];
            });
            setFeedbackErrors({ ...feedbackErrors });
            let isValid = false;


            isValid = validateAnyFormField('First Name', FirstName, 'text', null, 200, true);
            if (isValid == false) {
                feedbackErrors.FirstName = 'Please check your First Name';
            }


            isValid = validateAnyFormField('Last Name', LastName, 'text', null, 150, true);
            if (isValid == false) {
                feedbackErrors.LastName = 'Please check your Last Name';
            }

            isValid = validateAnyFormField('MobileNo', MobileNo, 'number', 10, 10, true);
            if (isValid == false) {
                feedbackErrors.MobileNo = 'Phone Number must be 10 characters long (numbers only).';
            }

            isValid = validateAnyFormField('Email', EmailAddress, 'email', null, 150, true);
            if (isValid == false) {
                feedbackErrors.EmailAddress = 'Please enter a valid email address.';
            }

            isValid = validateAnyFormField('CnfEmail', CnfEmailAddress, 'email', null, 150, true);
            if (isValid == false) {
                feedbackErrors.CnfEmailAddress = 'Please enter a valid email address that matches.';
            }

            isValid = validateAnyFormField('Password', Password, 'password', null, 150, true);
            if (isValid == false) {
                feedbackErrors.Password = 'Please enter a password.';
            }

            isValid = validateAnyFormField('CnfPassword', CnfPassword, 'password', null, 150, true);
            if (isValid == false) {
                feedbackErrors.Password = 'Please enter a password that matches.';
            }

            if (Object.keys(feedbackErrors).length > 0) {
                // Invalid fields
                setFeedbackErrors({ ...feedbackErrors });
                return;
            }

            //Check if both email match
            if (EmailAddress != CnfEmailAddress) {
                isValid = false;
                setShowErrorResponse(true);
                setShowSuccessResponse(false);
                setErrorResponse("The Email and the Confirm Email fields must be identical.");
                return false;
            }

            //Check if both passwords match
            if (Password != CnfPassword) {
                isValid = false;
                setShowErrorResponse(true);
                setShowSuccessResponse(false);
                setErrorResponse("The Password and the Confirm Password fields must be identical.");
                return false;
            }

            //Regex for password complexity            
            const validPassword = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$");
            console.log(validPassword.test(Password));
            if (!validPassword.test(Password)) {
                setShowErrorResponse(true);
                setShowSuccessResponse(false);
                setErrorResponse("Your password must be at least 8 characters in length and must include Uppercase, Lowercase, Number and Special Character.")
                return false;
            }

            if (isValid) {

                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }

                const param = {
                    requestParameters: {
                        SUFirstname: FirstName,
                        SULastname: LastName,
                        SUEmail: EmailAddress,
                        SULogin: EmailAddress,
                        SUPassword: Password,
                        SUPhone: MobileNo,
                        SUCompany : Company.trim()
                    },
                };


                //--make api call for data operation
                const response = await MakeApiCallAsync(Config.END_POINT_NAMES['REGISTER_CUSTOMER'], null, param, headers, "POST", true);
                if (response != null && response.data != null) {
                    let userData = JSON.parse(response.data.data);
                    if (userData.length > 0 && userData[0].ResponseMsg != undefined && userData[0].ResponseMsg == "Login Successfully") {
                        showSuccessMsg("Signup Successfully!");

                        localStorage.setItem("user", userData[0]);
                        dispatch(rootAction.userAction.setUser(userData[0]));

                        //--set Token in local storage
                        localStorage.setItem("Token", response.data.token ?? "");

                        //--if a cart ID is returned, hold it for for future use
                        localStorage.setItem("CartID", userData[0].CartID ?? "");

                        //--if a cust no is returned, hold it for for future use
                        localStorage.setItem("EcomCustNo", userData[0].EcomCustNo ?? "");

                        //--set HQ account
                        localStorage.setItem("SiteHqAccount", userData[0].CustHqAccount ?? "");

                        //--set Default SC
                        localStorage.setItem("SiteDefaultSC", userData[0].CustDefaultSc ?? "");

                        //--set BE account number
                        localStorage.setItem("BECustNo", userData[0].BECustNo ?? "");

                        //set company number
                        localStorage.setItem("CompanyNumber", userData[0].CompanyNumber?.toString() ?? "");

                        navigate('/' + getLanguageCodeFromSession() + '/');

                    } else if (userData.length > 0 && userData[0].ResponseMsg != undefined) {
                        setShowErrorResponse(true);
                        setShowSuccessResponse(false);
                        setErrorResponse(userData[0].ResponseMsg);
                        return false;
                    } else {
                        setShowErrorResponse(true);
                        setShowSuccessResponse(false);
                        setErrorResponse("An error occured. Please try again!");
                        return false;
                    }
                }
            }



        } catch (err) {
            console.log(err);
            setShowErrorResponse(true);
            setShowSuccessResponse(false);
            setErrorResponse("An error occured. Please try again!");

            return false;

        } finally {
            //--stop loader
            dispatch(rootAction.commonAction.stopLoading());
            setIsProcessing(false);
        }
    }


    useEffect(() => {
        // declare the data fetching function
        const dataOperationInUseEffect = async () => {

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            const paramCountry = {
                requestParameters: {

                    recordValueJson: "[]",
                },
            };


            //--Get countreis list
            const responseCountries = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_COUNTRIES_LIST'], null, paramCountry, headers, "POST", true);
            if (responseCountries != null && responseCountries.data != null) {
                await setCountriesList(JSON.parse(responseCountries.data.data));

            }

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Signup"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }


        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        dataOperationInUseEffect().catch(console.error);

        //--stop loader
        dispatch(rootAction.commonAction.stopLoading());


    }, [])


    return (
        <>
            <Seo title="Sign Up" description="Sign Up" keywords="Sign Up" />
            <LoadingScreen loading={isProcessing}/>
            {/* <SiteBreadcrumb title="Signup" /> */}

            <section className="signup-area ptb-60">
                <div className="container">
                    <h2 className="account-page-title mt-6 mt-lg-8 text-center">
                        <span className="dot"></span>
                        <span id="lbl_singup_crtaccount">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Create Account", "lbl_singup_crtaccount")
                                :
                                "Create Account"
                            }
                        </span>
                    </h2>
                    <div className="text-center mb-5">
                        Creating an account is easy. Fill out the form below and enjoy the exclusive benefits.
                    </div>
                    <div className='row justify-content-center mb-8 mb-lg-9'>
                        <div className="signup-content col-md-6 login-columns mt-5 mt-md-0">
                            <div className='card border-0 card--has-bg-color account-sections-card'>

                                <div className="card-body">
                                    <form className="signup-form" onSubmit={handleSignupForm}>
                                        <div className='row'>
                                            {showErrorResponse &&
                                                <div className="col-12" role="alert" aria-hidden="false" data-acsb-hidden="false">
                                                    <div className="alert alert-danger my-2" aria-hidden="false" data-acsb-hidden="false">{ErrorResponse}</div>
                                                </div>}
                                            {showSuccessResponse &&
                                                <div className="col-12" role="alert" aria-hidden="false" data-acsb-hidden="false">
                                                    <div className="alert alert-danger my-2" aria-hidden="false" data-acsb-hidden="false">{SuccessResponse}</div>
                                                </div>}

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "First Name", "lbl_singup_fname")
                                                            :
                                                            "First Name"
                                                        }
                                                    </Label>
                                                    <Input type='text' name='FirstName' id='FirstName' value={FirstName}
                                                        onChange={(event) => setFirstName(event.target.value)}
                                                        onFocus={(event) => removeFeedbackError(event.target.id)}
                                                        invalid={!!feedbackErrors.FirstName}
                                                    >
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.FirstName}</FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Last Name", "lbl_singup_lname")
                                                            :
                                                            "Last Name"
                                                        }
                                                    </Label>
                                                    <Input type='text' name='LastName' id='LastName' value={LastName}
                                                        onChange={(event) => setLastName(event.target.value)}
                                                        onFocus={(event) => removeFeedbackError(event.target.id)}
                                                        invalid={!!feedbackErrors.LastName}
                                                    >
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.LastName}</FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "COMPANY (OPTIONAL)", "lbl_singup_contact")
                                                            :
                                                            "COMPANY (OPTIONAL)"
                                                        }
                                                    </Label>
                                                    <Input type='text' name='Company' id='Company' value={Company}
                                                        onChange={(event) => setCompany(event.target.value)}
                                                        onFocus={(event) => removeFeedbackError(event.target.id)}
                                                        invalid={!!feedbackErrors.Company}
                                                    >
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.Company}</FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "PHONE NUMBER", "lbl_singup_contact")
                                                            :
                                                            "PHONE NUMBER"
                                                        }
                                                    </Label>
                                                    <Input type='text' name='MobileNo' id='MobileNo' value={MobileNo} maxLength='10'
                                                        onChange={(event) => setMobileNo(event.target.value)}
                                                        onFocus={(event) => removeFeedbackError(event.target.id)}
                                                        invalid={!!feedbackErrors.MobileNo}
                                                    >
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.MobileNo}</FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "EMAIL ADDRESS", "lbl_singup_email")
                                                            :
                                                            "EMAIL ADDRESS"
                                                        }
                                                    </Label>
                                                    <Input type='text' name='EmailAddress' id='EmailAddress' value={EmailAddress}
                                                        onChange={(event) => setEmailAddress(event.target.value)}
                                                        onFocus={(event) => removeFeedbackError(event.target.id)}
                                                        invalid={!!feedbackErrors.EmailAddress}
                                                    >
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.EmailAddress}</FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "CONFIRM EMAIL ADDRESS", "lbl_singup_confirm_email")
                                                            :
                                                            "CONFIRM EMAIL ADDRESS"
                                                        }
                                                    </Label>
                                                    <Input type='text' name='CnfEmailAddress' id='CnfEmailAddress' value={CnfEmailAddress}
                                                        onChange={(event) => setCnfEmailAddress(event.target.value)}
                                                        onFocus={(event) => removeFeedbackError(event.target.id)}
                                                        invalid={!!feedbackErrors.CnfEmailAddress}
                                                    >
                                                    </Input>
                                                    <FormFeedback type='invalid'>{feedbackErrors.CnfEmailAddress}</FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Password", "lbl_singup_password")
                                                            :
                                                            "Password"
                                                        }
                                                    </Label>
                                                    <div className="position-relative">
                                                        {Pass ?
                                                            <Input type='password' name='Password' id='Password' value={Password}
                                                                onChange={(event) => setPassword(event.target.value)}
                                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                                invalid={!!feedbackErrors.Password}
                                                            >
                                                            </Input>
                                                        :
                                                            <Input type='text' name='Password' id='Password' value={Password}
                                                                onChange={(event) => setPassword(event.target.value)}
                                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                                invalid={!!feedbackErrors.Password}
                                                            >
                                                            </Input>
                                                        }
                                                        <span className={'show-password link-primary cursor-pointer ' + (feedbackErrors.Password ? 'invalid' : '')} onClick={() => setPassToggle(!Pass)}>
                                                            {Pass ?
                                                                <>
                                                                    {LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                                        :
                                                                        "Show"
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                                        :
                                                                        "Hide"
                                                                    }
                                                                </>
                                                            }
                                                        </span>
                                                        <FormFeedback type='invalid'>{feedbackErrors.Password}</FormFeedback>
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div className='col-lg-6 col-md-6'>
                                                <FormGroup className='required'>
                                                    <Label>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Confirm Password", "lbl_singup_password")
                                                            :
                                                            "Confirm Password"
                                                        }
                                                    </Label>
                                                    <div className="position-relative">
                                                        {CnfPass ?
                                                            <Input type='password' name='CnfPassword' id='CnfPassword' value={CnfPassword}
                                                                onChange={(event) => setCnfPassword(event.target.value)}
                                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                                invalid={!!feedbackErrors.CnfPassword}
                                                            >
                                                            </Input>
                                                        :
                                                            <Input type='text' name='CnfPassword' id='CnfPassword' value={CnfPassword}
                                                                onChange={(event) => setCnfPassword(event.target.value)}
                                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                                invalid={!!feedbackErrors.CnfPassword}
                                                            >
                                                            </Input>
                                                        }
                                                        <span className={'show-password link-primary cursor-pointer ' + (feedbackErrors.CnfPassword ? 'invalid' : '')} onClick={() => setCnfPassToggle(!CnfPass)}>
                                                            {CnfPass ?
                                                                <>
                                                                    {LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                                        :
                                                                        "Show"
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {LocalizationLabelsArray.length > 0 ?
                                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                                        :
                                                                        "Hide"
                                                                    }
                                                                </>
                                                            }
                                                        </span>
                                                        <FormFeedback type='invalid'>{feedbackErrors.CnfPassword}</FormFeedback>
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div className='col-12' style={{display: "none"}}>
                                                <div className="form-group custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id='create-account-email-opt' />
                                                    <label className="custom-control-label text-body" htmlFor='create-account-email-opt'>Sign up for emails and updates from Pace University</label>
                                                    <div className="content-asset">
                                                        <small className="form-text">Send me emails about the products and services from Pace University. You can unsubscribe from emails at anytime. We do not rent or sell your information to any outside parties. For more information, please read our privacy policy.</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 mb-4' style={{display: "none"}}>
                                                <div className="form-group custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id='create-account-sms-opt' />
                                                    <label className="custom-control-label text-body" htmlFor='create-account-sms-opt'>I would like to receive text messages and updates from Pace University</label>
                                                    <div className="content-asset">
                                                        <small className="form-text">By subscribing to Pace University text messaging, you consent to receive recurring autodialed marketing messages to the mobile number used at opt-in. Consent is not a condition of purchase. Msg freq may vary. Msg & data rates may apply. Text STOP to 48751 to opt out. We do not rent or sell your information to any outside parties. For more information, please read our Terms of Use & Privacy</small>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className='col-lg-12 col-md-12'>
        <div className="form-group">
            <label>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Address", "lbl_singup_shippa")
                    :
                    "Shipping Address"
                } 
                <span className="required-field">*</span></label>
            <input type="text" className="form-control" placeholder="Enter shipping address" id="AddressLineOne" name="AddressLineOne"
                required={true}
                value={AddressLineOne}
                onChange={(e) => setAddressLineOne(e.target.value)}
            />
        </div>
    </div> */}

                                            <div className='col-md-6'>
                                                <button type="submit" className="btn btn-primary w-100" id="lbl_singup_submitbnt">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Create Account", "lbl_singup_submitbnt")
                                                        :
                                                        "Create Account"
                                                    }
                                                </button>
                                            </div>

                                            <div className='col-md-6 mt-5 mt-md-0'>
                                                <Link to={`/${getLanguageCodeFromSession()}/login`} className="bg-white btn btn-outline-primary w-100" id="lbl_singup_loginacnt">
                                                    {
                                                        "Login to your account"
                                                    }
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <BestFacilities /> */}


        </>
    );
}

export default Signup;
