import { api } from './api.ts'
import Config from '../helpers/Config.js'

// Define a service using a base URL and expected endpoints
export const checkoutApi = api.injectEndpoints({
    endpoints: (build) => ({
        confirmOrder: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        CustBillingAddress: {
                            full_name: payload.billingAddress?.fullName || '',
                            first_name: payload.billingAddress?.firstName || '',
                            last_name: payload.billingAddress?.lastName || '',
                            address1: payload.billingAddress?.address1 || '',
                            address2: payload.billingAddress?.address2 || '',
                            city: payload.billingAddress?.city || '',
                            state_code: payload.billingAddress?.stateCode || '',
                            postal_code: payload.billingAddress?.postalCode || '',
                            country_code: payload.billingAddress?.countryCode || '',
                            phone: payload.billingAddress?.phone || '',
                        },
                        CustShippingAddress: {
                            full_name: payload.shippingAddress?.fullName || '',
                            first_name: payload.shippingAddress?.firstName || '',
                            last_name: payload.shippingAddress?.lastName || '',
                            address1: payload.shippingAddress?.address1 || '',
                            address2: payload.shippingAddress?.address2 || '',
                            city: payload.shippingAddress?.city || '',
                            state_code: payload.shippingAddress?.stateCode || '',
                            postal_code: payload.shippingAddress?.postalCode || '',
                            country_code: payload.shippingAddress?.countryCode || '',
                            phone: payload.shippingAddress?.phone || '',
                        },
                        PGWResponse: payload.ccTokenResponse,
                        paymentMethods: payload.paymentMethods,
                        employee: payload.employee,
                        pdMaxDeductions: payload.pdMaxDeductions,
                        ccInfo: payload.creditCard,
                        shippingMethod: payload.shippingMethod,
                        paymentAmounts: payload.paymentAmounts
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.CONFIRM_ORDER,
                    method: 'POST',
                    body: params
                }
            },
            invalidatesTags: ['Cart']
        }),
        getShipToLocations: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        isOnAccount: payload?.isOnAccount,
                        locationId: payload?.locationId,
                        costCenterId: payload?.costCenterId
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.GET_SHIP_TO_LOCATIONS,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: any) => {
                const data = response.data || {};
                return {
                    shipToLocations: data?.map((o: any) => {
                        return {
                            id: o.address_id,
                            address1: o.address1,
                            address2: o.address2,
                            city: o.city,
                            countryCode: o.country_code,
                            postalCode: o.postal_code,
                            phone: o.phone,
                            email: o.email,
                            stateCode: o.state_code,
                            fullName: o.full_name,
                            c_addressType: o.c_axAddressType,
                            c_isSmartyValidated: o.c_isSmartyValidated
                        };
                    }) ?? []
                }
            }
        }),
        placeOrder: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        CustBillingAddress: payload.billingAddress,
                        customerPurchaseOrder: payload.customerPurchaseOrder
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.PLACE_ORDER,
                    method: 'POST',
                    body: params
                }
            },
            transformResponse: (response: any): any => {
                return response.data
            }
        }),
        tokenizeCard: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        BillingAddress: {
                            full_name: payload.billingAddress?.fullName || '',
                            address1: payload.billingAddress?.address1 || '',
                            city: payload.billingAddress?.city || '',
                            state_code: payload.billingAddress?.stateCode || '',
                            postal_code: payload.billingAddress?.postalCode || '',
                            country_code: payload.billingAddress?.countryCode || '',
                            phone: payload.billingAddress?.phone || '',
                        },
                        CCDetails: { nu: payload.ccNum, na: payload.ccName, ex: payload.ccExp, cv: payload.ccCvc, br: payload.ccBrand }
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.TOKENIZE_CC,
                    method: 'POST',
                    body: params,
                }
            }
        }),
        getShippingMethods: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        recordValueJson: '[]',
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.GET_SHIPPING_METHODS,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: any) => {
                return response.data;
            },
            providesTags: ['ShippingMethods']
        }),
        reviewOrder: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        ProductID: payload.productId,
                        ProductItemId: payload.lineItemID,
                        TargetQuantity: payload.newLineQuantity,
                        EmbConfig: payload.embroideryString,
                        EmbPrice: payload.embroideryPrice,
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.CONFIRM_ORDER,
                    method: 'POST',
                    body: params,
                }
            }
        }),
        getPaymentMethods: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        recordValueJson: '[]',
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.GET_PAYMENT_METHODS,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: any) => {
                return response.data;
            },
            providesTags: ['PaymentMethods']
        }),
        validateAddress: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        Street: payload.address1,
                        City: payload.city,
                        State: payload.stateCode,
                        Zipcode: payload.postalCode,
                        MaxCandidates: 1,
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.VALIDATE_ADDRESS,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: any) => {
                return response.data;
            }
        })
    })
})

export const {
    useConfirmOrderMutation,
    usePlaceOrderMutation,
    useReviewOrderMutation,
    useGetShipToLocationsQuery,
    useTokenizeCardMutation,
    useGetShippingMethodsQuery,
    useGetPaymentMethodsQuery,
    useLazyValidateAddressQuery,
    useValidateAddressQuery,
} = checkoutApi
