
import rootAction from "../../../stateManagment/actions/rootAction";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetCartDataQuery } from '../../../services/cart.ts';
import { useAppDispatch } from "../../../stateManagment/reduxStore";

const SizingBoard = () => {
    useGetCartDataQuery();
    const dispatch = useAppDispatch();
    const currentCart = useSelector((state) => state.cartReducer);
    const [displayData, setDisplayData] = useState([]);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer);
    const [isVisible, setIsVisible] = useState(true);
    const hideList = ['checkout', 'order-review', 'order-confirmation'];
    const location = useLocation();

    useEffect(() => {
        //check visibility
        var bit = true;
        var urlParts = location.pathname.split('/');
        for (var i = 0; i < hideList.length; i++)
            if (urlParts.indexOf(hideList[i]) > -1)
                bit = false;
        setIsVisible(bit);   
    }, [location]);
    const sortOrder = {'Tops':1, 'Bottoms':2, 'Outerwear':3};

    useEffect(() => {
        // Add classes not there in cart
        const groupedByCategory = currentCart?.products?.reduce((accumulator, item) => {
            const { itemClass, quantity } = item;

            if (!accumulator[itemClass]) {
                accumulator[itemClass] = { items: [], total: 0, allowed: 0 };
            }

            accumulator[itemClass].items.push(item);
            accumulator[itemClass].total += quantity;
            try {
                accumulator[itemClass].allowed = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClass == itemClass)?.MaxQuantityPerOrder || 0;
            } catch (error) {
                accumulator[itemClass].allowed = 0;
            }

            return accumulator;

        }, {});

        let updatedData = (groupedByCategory && Object.keys(groupedByCategory).length > 0) ? groupedByCategory : {};
        try {
            for (let i = 0; i < userSizingTemplate?.SizingTemplates[0]?.Quotas?.length; i++) {
                if (!Object.keys(updatedData).includes(userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClass)) {
                    updatedData[userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClass] = { items: [], total: 0, allowed: userSizingTemplate.SizingTemplates[0]?.Quotas[i].MaxQuantityPerOrder };
                }
            }

            setDisplayData(Object.entries(updatedData));
            dispatch(rootAction.commonAction.setAllotmentData(updatedData));
        } catch (error) {

        }
    }, [currentCart]);

    const getDisplayData = (dd) => {
        return Object.entries(dd).map(([category, data]) => (
            <tr>
                <td style={{ width: "auto", padding: "8px", backgroundColor: "#a6a6a6", border: "1px solid black" }} >{category}</td>
                <td style={{ width: "auto", padding: "8px", border: "1px solid black" }} className='text-center'>{data.total}</td>
                <td style={{ width: "auto", padding: "8px", border: "1px solid black" }} className='text-center'>{data.allowed}</td>
                <td style={{ width: "auto", padding: "8px", border: "1px solid black" }} className='text-center'>{data.allowed - data.total}</td>
            </tr>
        ));
    }

    return (
        <div id="sizingBoardContainer" className={`container ${!isVisible ? 'd-none' : ''}`}>
            <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid black" }}>
                <thead>
                    <tr>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px" }}>Category</th>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px" }} className='text-center'>In Cart</th>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px"  }} className='text-center'>Credits</th>
                        <th style={{ width: "auto", backgroundColor: "#404040", padding: "8px", border: "1px solid black", color: "#efefef", fontSize :"13px"  }} className='text-center'>Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    {displayData?.sort((a,b) => {
                        return (sortOrder[a[0]] - sortOrder[b[0]]) || a[0].localeCompare(b[0]);
                    }).map(([category, data]) => (
                    <tr>
                        <td style={{width: "auto" , padding: "8px", backgroundColor: "#a6a6a6" , border: "1px solid black", color: "#000000", fontSize :"13px"}} ><b>{category}</b></td>
                        <td style={{width: "auto" , padding: "8px", border: "1px solid black", fontSize :"13px"}} className='text-center'>{data.total}</td>
                        <td style={{width: "auto" , padding: "8px", border: "1px solid black", fontSize :"13px"}} className='text-center'>{data.allowed}</td>
                        <td style={{width: "auto" , padding: "8px", border: "1px solid black", fontSize :"13px"}} className='text-center'>{data.allowed-data.total}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default SizingBoard;