import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ProductsFilterOptions from "../../components/products/ProductsFilterOptions";
import ProductsGridTypeOne from "../../components/products/ProductsGridTypeOne";
import { SiteLeftSidebarFilter } from "../../components/shared/SiteLeftSidebarFilter";
import { SiteMobileFilter } from "../../components/shared/SiteMobileFilter";
import SitePagination from "../../components/shared/SitePagination";

import { useSelector } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import Seo from "../../components/shared/Seo";
import {
  useLazyGetProductsQuery,
} from "../../../services/product";
import LoadingScreen from "../../components/shared/LoadingScreen";

const BreadCrumb = (props) => {
  return (
    <SiteBreadcrumb title={props.title} />
  );
};

const AllProducts = () => {
  const dispatch = useAppDispatch();

  const plpState = useSelector((state) => state.plpReducer);
  const [previousCategory, setPreviousCategory] = useState('');
  //--set product id from url
  const siteCategories = useSelector(state => state.commonReducer.categories);
  const params = useParams();
  const parentCategoryName = siteCategories[params.parent_category_id] ?? siteCategories['root'];
  const [usableRefinements, setUsableRefinements] = useState({});
  const [plpContainerHeight, setPlpContainerHeight] = useState(null);

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
        setWindowInnerWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (plpState.searchPerformed) {
      if (plpState.resultsLayout === 'row-cols-md-3' || plpState.resultsLayout === 'row-cols-md-4') {
        if (Object.keys(plpState.availableRefinements).length === 0) {
          dispatch(rootAction.plpActions.setResultsLayout("row-cols-md-5"));
        }
      }
    }
  }, [plpState.availableRefinements]);

  const [
    getProducts,
    {
      isLoading: isProductLoading,
      isFetching: isProductFetching,
      data: productResponse,
    },
  ] = useLazyGetProductsQuery();

  useEffect(() => {
    let refinements = null;
    Object.keys(plpState.availableRefinements).forEach((attr) => {
      if (!refinements) refinements = {};
      const filteredRefinements = plpState.refinements[attr]?.filter(
        (refinement) => {
          const availableOptions =
            plpState.availableRefinements?.[attr]?.options
              ?.filter((option) => option.hit_count > 0)
              ?.map((option) => option.value) || [];
          return availableOptions.includes(refinement);
        }
      );
      refinements[attr] = filteredRefinements || [];
    });
    let cleanedRefinements = refinements || plpState.refinements;

    // Clean refinements if category changed
    if (params.category_id != plpState?.refinements?.cgid && params.category_id != previousCategory) {
        cleanedRefinements = [];
    }
    setUsableRefinements(cleanedRefinements);

    getProducts({
      refinements: { ...cleanedRefinements, cgid: [params.category_id] },
      paginationData: { ...plpState.paginationData, currentPage: params.page || 1 },
    });

    setPreviousCategory(params.category_id);
  }, [plpState.refinements, plpState.paginationData, params.page]);

  useEffect(() => { 
    if (plpState?.refinements?.cgid === params.category_id) {
      return;
    }

    setPreviousCategory(params.category_id);
    dispatch(rootAction.plpActions.setCategoryRefinements(params.category_id));
  }, [params.category_id]);
  

  const handleGrid = (layout) => {
    dispatch(rootAction.plpActions.setResultsLayout(layout));
  };

  const handleMobileGrid = (layout) => {
    dispatch(rootAction.plpActions.setResultsMobileLayout(layout));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(rootAction.plpActions.setCurrentPage(pageNumber));
  };
  const seo = (
    <Seo
      title={`${productResponse?.CategoryName || 'All Products'}`}
      description={`${productResponse?.CategoryName || 'All Products'}`}
      keywords={`${productResponse?.CategoryName || 'All Products'}`}
    />
  );  

  return (
    <>
      <LoadingScreen loading={isProductLoading || isProductFetching} />
      {seo}
      <BreadCrumb title={parentCategoryName} />

      <section
        className="products-landing-wrapper pb-8 pb-md-9"
        id="products-landing-page"
      >
        <div className="container-fluid">
          <div className="section-title pb-4 pb-md-3">
            <h1 className="mb-0 mt-md-n2">
              {
                parentCategoryName || productResponse?.CategoryName
              }
              &nbsp;/ {productResponse?.CategoryName || ""}
            </h1>
          </div>
          {Object.keys(plpState.availableRefinements).length && (windowInnerWidth < 992) && plpState.totalRecords > 0 ? (
              <SiteMobileFilter refinements={usableRefinements} totalRecords={plpState.totalRecords} windowInnerWidth={windowInnerWidth} />
          ) : null}

          {!isProductFetching ? (
            <ProductsFilterOptions
              refinements={usableRefinements}
              handleGrid={handleGrid}
              handleMobileGrid={handleMobileGrid}
              setSortByFilter={rootAction.plpActions.setSortByFilter}
              totalRecords={plpState.totalRecords}
            />
          ) : null}

          <div className="row">
            {Object.keys(plpState.availableRefinements).length && (windowInnerWidth > 992) && plpState.totalRecords > 0 ? (
              <SiteLeftSidebarFilter totalRecords={plpState.totalRecords} windowInnerWidth={windowInnerWidth} />
            ) : null}

            <div
              className={`col ${plpState.totalRecords > 0 ? "plp-grid-column" : ""} ${
                Object.keys(plpState.availableRefinements).length
                  ? ""
                  : "plp-grid-fullwith"
              }`}
            >
              {!plpState.products?.length && plpState.searchPerformed ? (
                <div className="alert alert-info">
                  No products found in this category. If you are seeing this
                  after setting filters on the left then refresh the page to
                  reset the filters.
                </div>
              ) : null}
              <div style={{height: plpContainerHeight || plpState.offsetHeight || 'auto'}}>
                {productResponse?.respObject && !isProductFetching && !isProductLoading ? (
                  <ProductsGridTypeOne
                    setParentPlpContainerHeight={setPlpContainerHeight}
                    productResponse={productResponse}
                    productsList={plpState.products || []}
                  />
                ) : null}
              </div>

              {plpState.showPagination &&
                plpState.totalRecords > plpState.paginationData.pageSize &&
                !isProductLoading &&
                !isProductFetching && (
                  <SitePagination
                    totalRecords={plpState.totalRecords}
                    currentPage={Number(params.page || '1')}
                    pageSize={plpState.paginationData.pageSize}
                    setCurrentPage={handlePageChange}
                    key={params.page || 1}
                  />
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllProducts;
