import { useSelector } from "react-redux";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { formatCurrency } from "../../../helpers/ConversionHelper";
import { useContext } from "react";
import { useGetPaymentMethodsQuery } from "../../../services/checkout";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { CheckoutStripForm } from "../../components/cart/CheckoutStripForm";
import { FormGroup, Input, Label } from "reactstrap";
import ContentLoader from "react-content-loader";
import React, { useEffect, useState, Fragment } from "react";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useGetContentAssetQuery } from "../../../services/content";
import { CARD_MAPPING } from '../../../helpers/Constants';
import { ToggleContext } from "../../../ToggleProvider";
import { GiftCardForm } from "../../components/cart/GiftCardForm";

export const PaymentMethod = ({ stripeRef, address, inputDisabled = false }) => {
    const dispatch = useAppDispatch();
    const { data: customCreditPaymentContent, isFetching: isCustomCreditPaymentContentLoading } = useGetContentAssetQuery('payment-credit-amount');
    const { isFetching: isPaymentMethodFetching } = useGetPaymentMethodsQuery(null);
    const LocalizationLabelsArray = [];
    const { 
        user: {
            PayrollDeduction: payrollDeduction, 
            PDOrderInRecentFortnight
        } 
    } = useSelector((state) => state.userReducer);
    const { paymentAmounts, orderTotal, adjustedTotal } = useSelector(state => state.cartReducer);
    const { billingAddress } = useSelector(state => state.userReducer);
    const { billingAddress : checkoutBilling } = useSelector(state => state.checkout);
    const { paymentMethods: availablePaymentMethods, payrollMaxDeductions: maxDeductions, hasSelectPaymentOptions, selectedPaymentMethod } = useSelector((state) => state.checkout);
    
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    
    const [paymentTotal, ] = useState(adjustedTotal ?? orderTotal);
    const [visibleMethods, setVisibleMethods] = useState({});
    const { payrollDeductionSelectOptions, siteDefaultLocation } = useSelector(state => state.commonReducer);

    const toggleOnAccount = (e) => {
        if (e.target.checked) {
            dispatch(rootAction.checkoutActions.setSelectedPaymentMethod('ON_ACCOUNT'));
            dispatch(rootAction.checkoutActions.setBillingAddress(siteDefaultLocation));
        } else {
            dispatch(rootAction.checkoutActions.setSelectedPaymentMethod('CREDIT_CARD'));
            dispatch(rootAction.checkoutActions.setBillingAddress(billingAddress));
        }

        setVisibleMethods({
            ...visibleMethods,
            ['UAB_PAYROLL_DEDUCTION']: !e.target.checked,
            ['CREDIT_CARD']: !e.target.checked
        });
    };

    const changePayroll = (e) => {
        var isVisible = e.target.value === '0';
        
        if (e.target.value !== '0') {
            dispatch(rootAction.checkoutActions.setSelectedPaymentMethod('UAB_PAYROLL_DEDUCTION'));
            dispatch(rootAction.checkoutActions.setBillingAddress(siteDefaultLocation));
            dispatch(rootAction.commonAction.setUserShipToLocationEnabled(false));
        } else {
            dispatch(rootAction.checkoutActions.setSelectedPaymentMethod('CREDIT_CARD'));
            dispatch(rootAction.checkoutActions.setBillingAddress(billingAddress));
        }

        setVisibleMethods({
            ...visibleMethods,
            ['CREDIT_CARD']: isVisible
        });
        dispatch(rootAction.checkoutActions.setPayrollMaxDeductions(e.target.value));
    };

    const getVisibility = (id, amount) => {
        if (id === "UAB_PAYROLL_DEDUCTION" && PDOrderInRecentFortnight)
            return false;
        
        if (visibleMethods == {} || visibleMethods[id] === undefined)
            return true;
        
        return visibleMethods[id];
    };

    if (!visibleMethods) {
        var selected = selectedPaymentMethod ?? 'CREDIT_CARD';
        var visibility = {
            'ON_ACCOUNT': true,
            'UAB_PAYROLL_DEDUCTION': selected !== 'ON_ACCOUNT',
            'CREDIT_CARD': selected === 'CREDIT_CARD'
        };
        setVisibleMethods(visibility);
    }
    useEffect(() => {
        if (!hasSelectPaymentOptions)
            return;

        if (selectedPaymentMethod === '' || selectedPaymentMethod === 'CREDIT_CARD') {
            dispatch(rootAction.checkoutActions.setSelectedPaymentMethod('CREDIT_CARD'));
            dispatch(rootAction.checkoutActions.setBillingAddress(billingAddress));
        }

        var selected = selectedPaymentMethod ?? 'CREDIT_CARD';
        var visibility = {
            'ON_ACCOUNT': true,
            'UAB_PAYROLL_DEDUCTION': selected !== 'ON_ACCOUNT',
            'CREDIT_CARD': selected === 'CREDIT_CARD'
        };
        setVisibleMethods(visibility);
    }, [selectedPaymentMethod, isPaymentMethodFetching, checkoutBilling]);

    const getContentLoader = (id) => {
        var size = 95;
        if (id === "UAB_PAYROLL_DEDUCTION")
            size = 170;
        if (id === "CREDIT_CARD")
            size = 360;
        return (
            <ContentLoader
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                height={size} className='w-100 mt-3'>
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
            </ContentLoader>)
    };

    return hasSelectPaymentOptions ? (
        <>
        <h6>
            {LocalizationLabelsArray.length > 0 ?
                replaceLoclizationLabel(LocalizationLabelsArray, "Payment Method", "lbl_check_paymentmethod")
                :
                "Payment Method"
            }
        </h6>

        {availablePaymentMethods?.map((paymentMethod, index) => {
            let amount = paymentAmounts[paymentMethod.id];
            return (
                getVisibility(paymentMethod.id, amount) &&
                <>
                {!inputDisabled ? 
                    <div className="card px-4 pt-2 mt-3 bg-light border-0" key={paymentMethod.id}>
                        {paymentMethod.id === "ON_ACCOUNT" &&
                            <OnAccount
                                amount={amount}
                                handleChange={toggleOnAccount}
                                inputDisabled={inputDisabled}
                                selected={selectedPaymentMethod === paymentMethod.id} />
                        }
                        {paymentMethod.id === "UAB_PAYROLL_DEDUCTION" &&
                            <PayrollDeduction
                                amount={amount}
                                handleChange={changePayroll}
                                payrollDeductionData={payrollDeduction}
                                inputDisabled={inputDisabled}
                                selected={selectedPaymentMethod === paymentMethod.id ? maxDeductions : 0} />
                        }
                        {paymentMethod.id === "CREDIT_CARD" &&
                            <CreditCard 
                                methodId={paymentMethod.id}
                                amount={amount}
                                cards={paymentMethod.cards}
                                stripeRef={stripeRef}
                                address={address} />
                        }
                    </div>
                    :
                    getContentLoader(paymentMethod.id)
                }
                </>
            )
        })}
        </>
    )
    : (
        <>
            <h6>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Payment Method", "lbl_check_paymentmethod")
                    :
                    "Payment Method"
                }
            </h6>
            {!isPaymentMethodFetching && paymentTotal > 0 ?
                (() => {
                    let remainingAmount = paymentTotal;
                    if (availablePaymentMethods?.length === 0) {
                        // no payment methods available feedback
                        return (
                            <FormGroup>
                                <Label className="text-danger">
                                    {LocalizationLabelsArray.length > 0 ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "No payment methods available. Please contact us for assistance.", "lbl_check_nopaymentmethod")
                                        :
                                        "No payment methods available. Please contact us for assistance."
                                    }
                                </Label>
                            </FormGroup>
                        )
                    }
                    return availablePaymentMethods?.map((paymentMethod, index) => {
                        let amount = paymentAmounts[paymentMethod.id] ?? 0;

                        if (paymentMethod.id === "GIFT_CERTIFICATE") {
                            return(
                                <Fragment key={paymentMethod.id}>
                                    <GiftCardForm></GiftCardForm>
                                </Fragment>
                            )
                        }
                        if (paymentMethod.id === "CREDIT_CARD") {
                            if (amount === 0)
                                return (<></>)
                            
                            return (
                                <React.Fragment key={paymentMethod.id}>
                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                        <span className='label'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Credit Card", "lbl_check_creditcard")
                                                :
                                                "Credit Card"
                                            }:&nbsp;{formatCurrency(amount)}
                                        </span>
                                        <div className="cards d-flex justify-content-center">
                                            {paymentMethod.cards?.map((card, index) => {
                                                return (
                                                    <span key={index} className={`creditcard ${CARD_MAPPING[card.name.toLowerCase()] ?? card.name.toLowerCase()}`}></span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <CheckoutStripForm
                                        ref={stripeRef}
                                        CustBillingAddress={address}
                                    />
                                </React.Fragment>
                            )
                        }
                        if (paymentMethod.id === "UAB_EMPLOYEE_CREDITS") {
                            return (
                                <div className='d-flex flex-wrap align-items-center mb-3' key={paymentMethod.id}>
                                    {customCreditPaymentContent?.ContentValue?.length > 0 ?
                                        <>
                                            <div className='label d-block' style={{ flex: '1 0 100%' }}>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                    :
                                                    paymentMethod.name
                                                }
                                            </div>
                                            <p className="font-weight-bold">
                                                {customCreditPaymentContent.ContentValue.replace("{amount}", formatCurrency(amount))}
                                            </p>
                                        </>
                                        :
                                        <>
                                            <span className='label'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                    :
                                                    paymentMethod.name
                                                }
                                                :&nbsp;
                                            </span>
                                            <span className="font-weight-bold">
                                                {formatCurrency(amount)}
                                            </span>
                                        </>
                                    }
                                </div>
                            )
                        }
                        if (paymentMethod.id === "UAB_PAYROLL_DEDUCTION" && !PDOrderInRecentFortnight) {
                            return (
                                <div className="clearfix" key={paymentMethod.id}>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='label' style={{ textTransform: 'none' }}>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                :
                                                paymentMethod.name
                                            }
                                            :&nbsp;
                                        </span>
                                        <span className="font-weight-bold">
                                            {formatCurrency(amount)}
                                        </span>
                                    </div>
                                    <div className='col-md-6 px-0 mb-3'>
                                        <FormGroup>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Max Deductions", "lbl_check_maxdeductions")
                                                    :
                                                    "Max Deductions"
                                                }
                                            </Label>
                                            {payrollDeduction.PayrollDeduction > 1 ?
                                                <Input type='select' required onChange={(e) => dispatch(rootAction.checkoutActions.setPayrollMaxDeductions(e.target.value))}>
                                                    <option value={0}>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Select", "lbl_check_select")
                                                            :
                                                            "Select"
                                                        }
                                                    </option>
                                                    {[...Array(payrollDeduction.PayrollDeduction).keys()].map((index) => {
                                                        var text = index + 1;
                                                        if (payrollDeductionSelectOptions?.length > 0) 
                                                        {
                                                            text = index === 0 ? 
                                                                payrollDeductionSelectOptions[0]
                                                                    .replace('$[[amount]]', formatCurrency(amount)) : 
                                                                payrollDeductionSelectOptions[1]
                                                                    .replace('$[[amount]]', formatCurrency(amount / (index + 1)))
                                                                    .replace('[[count]]', index + 1)
                                                        }
                                                        return (
                                                            <option key={index} value={index + 1}>{text}</option>
                                                        )
                                                    })}
                                                </Input>
                                                :
                                                <Input type='select' required disabled>
                                                    <option value={payrollDeduction.PayrollDeduction}>{payrollDeduction.PayrollDeduction}</option>
                                                </Input>
                                            }
                                        </FormGroup>
                                    </div>
                                </div>
                            )
                        }
                        if (paymentMethod.id === "ON_ACCOUNT") {
                            return (
                                <div className="clearfix" key={paymentMethod.id}>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='font-weight-normal' style={{ textTransform: 'none', fontSize: '1rem', letterSpacing: 0 }}>
                                            { isToggled ? "Allotment" :
                                            LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, paymentMethod.name, "lbl_check_creditcard")
                                                :
                                                paymentMethod.name
                                            }
                                            {!isToggled?<>
                                            :&nbsp;</>:<></>
                                            }
                                        </span>
                                        {!isToggled&&
                                        <span className="font-weight-bold">
                                            {formatCurrency(amount)}
                                        </span>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        return <React.Fragment key={paymentMethod.id}>
                        </React.Fragment>;
                    })
                })()
                :
                <ContentLoader
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    viewBox="0 0 900 150"
                    width='100%'
                    height={200}
                >
                    <rect x="0" y="0" width="100%" height="100%" />
                </ContentLoader>
            }
        </>
    )
}

const OnAccount = ({ handleChange, amount = 0, selected = false, inputDisabled }) => {
    const siteName = useSelector(state => state.commonReducer.siteName);
    const defaultMessage = `PLEASE BILL ${siteName} FOR THE PURCHASE OF THIS ORDER.`;
    const [message, setMessage] = useState(defaultMessage);
    const { data: onAccountMessage, isFetching: isOnAccountMessageLoading } = useGetContentAssetQuery('on-account-message');
    useEffect(() => {
        if (isOnAccountMessageLoading) 
            return;

        if (!!onAccountMessage?.ContentValue)
            setMessage(onAccountMessage?.ContentValue)
        else setMessage(defaultMessage);
    }, [onAccountMessage]);
    return (
        <>
        <div className="d-flex justify-content-between">
            <h6 className="mt-0">On Account</h6>
            <h6 className="mt-0">{formatCurrency(amount)}</h6>
        </div>
        <FormGroup className="form-group custom-control custom-checkbox" check>
            <Input type='checkbox' name='onAccount-chk' id='onAccount-chk' className='custom-control-input'
                onChange={(e) => handleChange(e)}
                checked={selected}
                disabled={inputDisabled}
            >
            </Input>
            <Label for='onAccount-chk' className='custom-control-label' check>
                <span className='label'>{message}</span>
            </Label>
        </FormGroup>
        </>
    )
}

const PayrollDeduction = ({ payrollDeductionData, amount = 0, handleChange, selected = 0, inputDisabled }) => {
    const dispatch = useAppDispatch();
    const LocalizationLabelsArray = [];
    const { PayrollDeduction: payrollDeduction, AvailablePDBalance: balance } = payrollDeductionData;
    const amountError = balance < amount;

    const { payrollDeductionSelectOptions } = useSelector(state => state.commonReducer);
    const { data: payrollDeductionContent, isFetching: isPayrollDeductionLoading } = useGetContentAssetQuery('payroll-deduction-description');
    return (
        <>
        <div className="d-flex justify-content-between align-items-center">
            <h6 className="mt-0">Payroll Deduction</h6>
            <h6 className={`mt-0 ${amountError ? 'text-danger' : ''}`}>{formatCurrency(amount)}</h6>
        </div>
        {amountError &&
            <div className="mb-2 text-danger" >
                <small>Amount is greater than available Balance. Please remove items or pay with Credit Card.</small>
            </div>
        }
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: payrollDeductionContent?.ContentValue }} />
        <FormGroup>
            <Label>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Max Deductions", "lbl_check_maxdeductions")
                    :
                    "Max Deductions"
                }
            </Label>
            <Input type='select' value={selected} disabled={inputDisabled || amountError} onChange={(e) => handleChange(e)}>
                <option value={0}>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Select an option to use Payroll Deduction", "lbl_check_select")
                        :
                        "Select an option to use Payroll Deduction"
                    }
                </option>
                {[...Array(payrollDeduction).keys()].map((index) => {
                    var text = index + 1;
                    if (payrollDeductionSelectOptions?.length > 0) 
                    {
                        text = index === 0 ? 
                            payrollDeductionSelectOptions[0]
                                .replace('$[[amount]]', formatCurrency(amount)) : 
                            payrollDeductionSelectOptions[1]
                                .replace('$[[amount]]', formatCurrency(amount / (index + 1)))
                                .replace('[[count]]', index + 1)
                    }
                    return (
                        <option key={index} value={index + 1}>{text}</option>
                    )
                })}
            </Input>
        </FormGroup>
        </>
    )
}

const CreditCard = ({ methodId, amount = 0, cards = [], stripeRef, address }) => {
    const LocalizationLabelsArray = [];
    return (
        <React.Fragment key={methodId}>
            <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                <h6 className="my-0">
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Credit Card", "lbl_check_creditcard")
                        :
                        "Credit Card"
                    }
                </h6>
                <div className="cards d-flex justify-content-center order-3 order-sm-0 px-0 col-12 col-sm-auto">
                    {cards?.map((card, index) => {
                        return (
                            <span key={index} className={`creditcard ${CARD_MAPPING[card.name.toLowerCase()] ?? card.name.toLowerCase()}`}></span>
                        )
                    })}
                </div>
                <h6 className="my-0">{formatCurrency(amount)}</h6>
            </div>
            <CheckoutStripForm
                ref={stripeRef}
                CustBillingAddress={address}
            />
        </React.Fragment>
    )
}

const Credits = ({ amount = 0 }) => {
    const defaultMessage = '';
    const [message, setMessage] = useState(defaultMessage);
    const { data: customCreditPaymentContent, isFetching: isCustomCreditPaymentContentLoading } = useGetContentAssetQuery('payment-credit-amount');
    useEffect(() => {
        if (isCustomCreditPaymentContentLoading) 
            return;

        if (!!customCreditPaymentContent?.ContentValue)
            setMessage(customCreditPaymentContent?.ContentValue)
        else setMessage(defaultMessage);
    }, [customCreditPaymentContent]);
    return (
        <>
            <div className="d-flex justify-content-between">
                <h6 className="mt-0">Credits</h6>
                <h6 className="mt-0">{formatCurrency(amount)}</h6>
            </div>
            {!!message &&
                <p className="font-weight-bold">
                    {message?.replace("{amount}", formatCurrency(amount))}
                </p>
            }
        </>
    )
}