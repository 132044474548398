import { useEffect, useRef, useState } from 'react';
import { ProductBit } from './ProductBit';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import { useSelector } from 'react-redux';

const ProductsGridTypeOne = ({ productDetailPageForceUpload, productResponse, setParentPlpContainerHeight }) => {
    const dispatch = useAppDispatch();
    const plpState = useSelector((state) => state.plpReducer);
    const { respObject: products } = productResponse;
    const plpContainer = useRef(null);
    const [plpContainerHeight, setPlpContainerHeight] = useState('auto');

    useEffect(() => {
        if(!plpContainer.current) return;
    
        setPlpContainerHeight(plpContainer.current.offsetHeight);
        dispatch(rootAction.plpActions.setCurrentHeight(plpContainer.current.offsetHeight));

    }, [plpContainer.current]);

    const updateHeight = () => {
        setPlpContainerHeight('auto');
        setParentPlpContainerHeight('auto');
    };

    useEffect(() => {
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, []);

    return (
        <div
            ref={plpContainer} style={{height: plpContainerHeight || plpState.offsetHeight || 'auto'}}
            id="products-collections-listing"
            className={`row product-grid pt-4 pt-md-0 ${plpState.resultsMobileLayout} ${plpState.resultsLayout}`}
        >
            {products?.map((item, idx) =>
                <ProductBit product={item} key={item.ProductId+item.CategoryID+item.colorSwatches?.[0]?.code} productDetailPageForceUpload={productDetailPageForceUpload} />
            )}
        </div>
    );

}


export default ProductsGridTypeOne;

