import { useEffect,Fragment, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { formatCurrency } from "../../../helpers/ConversionHelper";
import { useSelector } from "react-redux";
import { useGetCartDataQuery } from "../../../services/cart";

export const OrderSummary = ({ id, cart }) => {
    useGetCartDataQuery();
    const currentCart = useSelector((state) => state.cartReducer);
    const LocalizationLabelsArray = [];
    
    const { selectedPaymentMethod } = useSelector((state) => state.checkout);

    const adjustedTax = (cart.paymentAmounts[selectedPaymentMethod] ?? cart.orderTotal) - cart.shippingSubTotal - cart.cartSubTotal;
    const adjustedTotal = cart.paymentAmounts[selectedPaymentMethod] ?? cart.orderTotal;
    const showAdjusted = adjustedTotal != cart?.orderTotal;
    
    const [gcAmount, setGcAmount] = useState(0);
    useEffect(() => {
        setGcAmount(0);
        if (cart && Object.keys(cart?.paymentAmounts).length > 0) {
            var gcTotal = 0;
            Object.keys(cart?.paymentAmounts).map((o) => {
                if (o.indexOf('GC:') === 0)
                    gcTotal += cart.paymentAmounts[o];
            })
            setGcAmount(gcTotal);
        }
    },[currentCart])
    
    return (
        <Card id={id} color="light" className="card-sidebar mb-5 order-summary">
            <CardHeader tag="h5">
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Order Summary", "lbl_check_ordersummary")
                    :
                    "Order Summary"
                }
            </CardHeader>
            <CardBody>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Item Subtotal", "lbl_check_itemsubtotal")
                                :
                                "Item Total"
                            }
                        </span>
                        <span>{formatCurrency(cart?.cartSubTotal + cart?.savingsTotal)}</span>
                    </li>
                    
                    {cart?.savingsTotal>0?<Fragment>
                    <li className="list-group-item">
                        <span style={{fontWeight: "bold"}} className = "text-danger">
                        Discounts                            
                        </span>
                        <span style={{fontWeight: "bold"}} className = "text-danger">-{formatCurrency(cart?.savingsTotal)}</span>
                    </li>

                    <li className="list-group-item">
                        <span>
                        Item Subtotal                            
                        </span>
                        <span>{formatCurrency(cart?.cartSubTotal)}</span>
                    </li></Fragment>:<></>}

                    <li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Shipping cost", "lbl_check_shippingcost")
                                :
                                "Shipping Cost"
                            }
                        </span>
                        <span>{formatCurrency(cart?.shippingSubTotal)}</span>
                    </li>
                    <li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Estimated Sales Tax", "lbl_check_estimatedsalestax")
                                :
                                "Estimated Sales Tax"
                            }
                        </span>
                        <span>{formatCurrency(showAdjusted ? adjustedTax : cart?.taxTotal)}</span>
                    </li>
                    {gcAmount>0&&<li className="list-group-item">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Gift Cards Applied", "lbl_check_estimatedsalestax")
                                :
                                "Gift Cards Applied"
                            }
                        </span>
                        <span>{formatCurrency(gcAmount)}</span>
                    </li>}
                    <li className="list-group-item order-summary-total">
                        <span>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Estimated Billed Amount", "lbl_check_estimatedbilledamount")
                                :
                                "Estimated Billed Amount"
                            }
                        </span>
                        <span>
                            {formatCurrency(showAdjusted ? adjustedTotal - gcAmount : cart?.orderTotal - gcAmount)}
                        </span>
                    </li>
                    {cart?.savingsTotal>0?<Fragment>
                    <div className="row order-savings justify-content-center mb-1 mb-md-4 ">
                        <span style={{fontWeight : "bold"}} className="text-danger">Your savings:</span> &nbsp; <span style={{fontWeight : "bold"}} className="text-danger">{formatCurrency(cart?.savingsTotal)}</span>
                    </div></Fragment>:<></>}
                </ul>
            </CardBody>
        </Card>
    )
}