import { createSlice } from '@reduxjs/toolkit';
import { checkoutApi } from '../../services/checkout';

type CreditCardSafe = {
    last4: string
    cardType: string
    expMonth: string
    expYear: string
}

type CheckoutActivity = {
    paymentMethod: string
    creditCard: CreditCardSafe,
    billingAddress?: any,
    shippingAddress?: any,
    edittedAddress?: any,
    paymentMethods?: any[] | null,
    shippingMethod?: any | null,
    payrollMaxDeductions: number,
    enoughBalance: boolean,
    editableBilling: boolean,
    editableShipping: boolean,
    addressBook: any[],
    canAddNewBillingAddress: boolean,
    shipToLocationEnabled: boolean,
    shipToLocations: any[],
    creditCardValid: boolean,
    shippingOnlyFromDropdown: boolean,
    customerPurchaseOrder: string,
    isInOrderReview: boolean | null,
    paymentAmounts: any,
    hasSelectPaymentOptions: boolean,
    selectedPaymentMethod: string;
}

const initialState: CheckoutActivity = {
    paymentMethod: '',
    creditCard: {
        last4: '',
        cardType: '',
        expMonth: '',
        expYear: ''
    },
    billingAddress: null,
    shippingAddress: null,
    edittedAddress: null,
    paymentMethods: null,
    shippingMethod: null,
    payrollMaxDeductions: 0,
    enoughBalance: false,
    editableBilling: false,
    editableShipping: false,
    addressBook: [],
    canAddNewBillingAddress: false,
    creditCardValid: false,
    shippingOnlyFromDropdown: false,
    shipToLocationEnabled: false,
    shipToLocations: [],
    customerPurchaseOrder: '',
    isInOrderReview: null,
    paymentAmounts: {},
    hasSelectPaymentOptions: false,
    selectedPaymentMethod: ''
};

export const setAvailablePaymentMethods = (paymentMethods: any, orderTotal: number, creditBalance: number, pdBalance: number, gcBalance: number, isPayrollDeductionEnabled: boolean, creditEnabled: boolean, isGiftCardEnabled: boolean, isOnAccount: boolean = false) => {
    if (paymentMethods?.applicable_payment_methods.length > 0 && orderTotal > 0) {
        // check for special case where 3 payment methods are available and 1 needs to be selected.
        const creditCardPaymentMethod = paymentMethods?.applicable_payment_methods
            .find((paymentMethod: any) => paymentMethod.id === 'CREDIT_CARD');
        const onAccountPaymentMethod = paymentMethods?.applicable_payment_methods
            .find((paymentMethod: any) => paymentMethod.id === 'ON_ACCOUNT');
        const payrollDeductionPaymentMethod = paymentMethods?.applicable_payment_methods
            .find((paymentMethod: any) => paymentMethod.id === 'UAB_PAYROLL_DEDUCTION');

        if (!!creditCardPaymentMethod &&
            !!onAccountPaymentMethod && isOnAccount &&
            !!payrollDeductionPaymentMethod) {
            return [onAccountPaymentMethod, payrollDeductionPaymentMethod, creditCardPaymentMethod]
        }

        if (!!creditCardPaymentMethod &&
            !!payrollDeductionPaymentMethod) {
            return [payrollDeductionPaymentMethod, creditCardPaymentMethod]
        }
    
        if (onAccountPaymentMethod && isOnAccount) {
            return [onAccountPaymentMethod]
        }
        const combinedBalance = creditBalance + pdBalance;
        const allPaymentMethodsExceptCreditCard = paymentMethods?.applicable_payment_methods
            .filter((paymentMethod: any) => {
                if (paymentMethod.id === 'GIFT_CERTIFICATE') {
                    return isGiftCardEnabled;
                }
                if (paymentMethod.id === 'UAB_PAYROLL_DEDUCTION') {
                    return isPayrollDeductionEnabled;
                }
                if (paymentMethod.id === 'UAB_EMPLOYEE_CREDITS') {
                    return creditEnabled;
                }
                return paymentMethod.id !== 'CREDIT_CARD'
            });

        const allPaymentMethodsWithBalance = allPaymentMethodsExceptCreditCard.filter((paymentMethod: any) => {
            if (paymentMethod.id === 'UAB_PAYROLL_DEDUCTION') {
                return pdBalance > 0;
            }
            if (paymentMethod.id === 'GIFT_CERTIFICATE') {
                return gcBalance > 0 && creditBalance === 0;
            }
            if (paymentMethod.id === 'UAB_EMPLOYEE_CREDITS') {
                return creditBalance > 0;
            }
            return false;
        });

        const findFirstAbleToPayMethod = allPaymentMethodsExceptCreditCard.find((paymentMethod: any) => {
            if (paymentMethod.id === 'UAB_PAYROLL_DEDUCTION') {
                return pdBalance >= orderTotal;
            }
            if (paymentMethod.id === 'UAB_EMPLOYEE_CREDITS') {
                return creditBalance >= orderTotal;
            }
            if (paymentMethod.id === 'GIFT_CERTIFICATE' && pdBalance === 0 && creditBalance === 0) {
                return gcBalance >= orderTotal;
            }
            return false;
        });

        if (findFirstAbleToPayMethod) {
            return [findFirstAbleToPayMethod]
        } else if (combinedBalance >= orderTotal) {
            return allPaymentMethodsExceptCreditCard
        } else {
            return [
                ...allPaymentMethodsWithBalance,
                ...creditCardPaymentMethod ? [creditCardPaymentMethod] : []
            ]
        }
    }
    console.log('some how it failed');
    return [];
}
export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        reset: (state, action) => {
            return {
                ...initialState,
                selectedPaymentMethod: state.selectedPaymentMethod,
                shippingAddress: action?.payload.force ? null : state.shippingAddress,
                billingAddress: action?.payload.force ? null : state.billingAddress
            };
        },
        setCreditCard: (state, action) => {
            const cleanedCcExp = action.payload.ccExp.replace(/\D/g, '');
            state.creditCard = {
                last4: action.payload.ccNum.slice(-4),
                cardType: action.payload.ccBrand,
                expMonth: cleanedCcExp.slice(0, 2),
                expYear: cleanedCcExp.slice(2, 4)
            };
        },
        setBillingAddress: (state, action) => {
            state.billingAddress = action.payload
        },
        setSelectedPaymentMethod: (state, action) => {
            state.selectedPaymentMethod = action.payload
        },
        setShippingAddress: (state, action) => {
            state.shippingAddress = action.payload
        },
        setPayments: (state, action) => {
            state.paymentMethods = action.payload
        },
        setShippingMethod: (state, action) => {
            state.shippingMethod = action.payload
        },
        setPayrollMaxDeductions: (state, action) => {
            state.payrollMaxDeductions = action.payload
        },
        setCustomerPurchaseOrder: (state, action) => {
            state.customerPurchaseOrder = action.payload
        },
        setPaymentAmounts: (state, action) => {
            state.paymentAmounts = action.payload;
        },
        setActivity: (state, action) => {
            const creditEnabled = action.payload.paymentMethods?.applicable_payment_methods.filter((method: any) => method.id === 'UAB_EMPLOYEE_CREDITS' && action.payload.creditEnabled)?.length > 0
            const payrollDeductionEnabled = action.payload.paymentMethods?.applicable_payment_methods.filter((method: any) => method.id === 'UAB_PAYROLL_DEDUCTION' && action.payload.isPayrollDeductionEnabled)?.length > 0
            const creditCardEnabled = action.payload.paymentMethods?.applicable_payment_methods.filter((method: any) => method.id === 'CREDIT_CARD' && action.payload.isPayrollDeductionEnabled)?.length > 0
            const creditBalance = creditEnabled ? action.payload.creditBalance : 0
            const pdBalance = payrollDeductionEnabled ? action.payload.pdBalance : 0
            const isOnAccount = action.payload.isOnAccount && action.payload.paymentMethods?.applicable_payment_methods.filter((method: any) => method.id === 'ON_ACCOUNT')?.length > 0;
            const isGiftCardEnabled = action.payload.paymentMethods?.applicable_payment_methods.filter((method: any) => method.id === 'GIFT_CERTIFICATE')?.length > 0
            const gcBalance = action.payload.gcBalance && action.payload.gcBalance > 0 ? action.payload.gcBalance : 0;

            state.paymentMethods = setAvailablePaymentMethods(action.payload.paymentMethods, action.payload.orderTotal, creditBalance, pdBalance, gcBalance, payrollDeductionEnabled, creditEnabled, isGiftCardEnabled, isOnAccount) ?? []
            state.hasSelectPaymentOptions = 
                (isOnAccount && payrollDeductionEnabled && creditCardEnabled) || // unique case for MLH 
                (payrollDeductionEnabled && creditCardEnabled); // this is global for all Payroll Deduction
        },
        setEdittedAddress: (state, action) => {
            state.edittedAddress = action.payload;
        },
        setShipToLocaton: (state, action) => {
            state.shipToLocationEnabled = action.payload.shipToLocationEnabled;
            state.shipToLocations = action.payload.shipToLocations;
        },
        setAddressActivity: (state, action) => {
            if (action.payload.siteLocations)
            {
                const employeeLocations = [
                    { ...action.payload.siteLocations.find((l: any) => l.axLocationId === action.payload.employeeLocation) }
                ].filter((l: any) => l.address1);
                state.addressBook = employeeLocations.map((location: any, index: number) => {
                    return {
                        ...location,
                        id: location.id || index + location.postalCode,
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        fullName: `${action.payload.firstName} ${action.payload.lastName}`
                    }
                });
            }
            const creditCardPaymentMethod = action.payload.paymentMethods?.applicable_payment_methods?.find((method: any) => {
                return method.id === 'CREDIT_CARD'
            });
            const onAccountPaymentMethod = action.payload.paymentMethods?.applicable_payment_methods?.find((method: any) => {
                return method.id === 'ON_ACCOUNT'
            });
            const isOnAccount = onAccountPaymentMethod && action.payload.customerOnAccount;

            if (!!action.payload.defaultBillingAddress)
                state.billingAddress = action.payload.defaultBillingAddress

            // billing edit rules
            if (isOnAccount && onAccountPaymentMethod) {
                state.editableBilling = false
            } else if (creditCardPaymentMethod || action.payload.billingAddressEditable) {
                state.editableBilling = true
                state.canAddNewBillingAddress = true
                state.billingAddress = action.payload.addressBookBillingAddress
            } else {
                state.editableBilling = false
            }

            // shipping edit rules
            state.shipToLocationEnabled = action.payload.shipToLocationEnabled;
            if (state.shipToLocationEnabled && action.payload.shippingAddressEditable && !action.payload.employeeLocations?.length && action.payload.employeeLocation) {
                state.editableShipping = true
                state.shippingOnlyFromDropdown = false
            } else if (state.shipToLocationEnabled && action.payload.shippingAddressEditable && action.payload.employeeLocations?.length) {
                state.shippingOnlyFromDropdown = true
                state.editableShipping = true
            } else if (state.shipToLocationEnabled && !action.payload.shippingAddressEditable && !action.payload.employeeLocations?.length && action.payload.employeeLocation) {
                state.shippingOnlyFromDropdown = false
                state.editableShipping = false
            } else if (state.shipToLocationEnabled && !action.payload.shippingAddressEditable && action.payload.employeeLocations?.length) {
                state.shippingOnlyFromDropdown = true
                state.editableShipping = true
            } else if (!state.shipToLocationEnabled && action.payload.shippingAddressEditable && !action.payload.employeeLocations?.length && action.payload.employeeLocation) {
                state.shippingOnlyFromDropdown = false
                state.editableShipping = true
            } else if (!state.shipToLocationEnabled && !action.payload.shippingAddressEditable && action.payload.employeeLocations?.length) {
                state.shippingOnlyFromDropdown = false
                state.editableShipping = true
            } else {
                state.shippingOnlyFromDropdown = false
                state.editableShipping = true
            }
            state.shipToLocations = action.payload.shipToLocations;
            if (state.shipToLocationEnabled && state.shipToLocations?.length > 0) {
                if (!state.shippingAddress || !state.shipToLocations.find(o => o.id === state.shippingAddress?.id)) {
                    state.shippingAddress = state.shipToLocations[0]; // if not already set, or existing, select the first ship to location
                }
            }
            else state.shippingAddress = state.addressBook?.[0]?.id ? state.addressBook[0] : action.payload.addressBookShippingAddress;
        },
        setIsInOrderReview: (state, action) => {
            state.isInOrderReview = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(checkoutApi.endpoints.getShippingMethods.matchFulfilled, (state, action) => {
                if (!state.shippingMethod) {
                    state.shippingMethod = action.payload.applicable_shipping_methods?.find((method: any) => method.id === action.payload.default_shipping_method_id) || {}
                }
            })
    }
})


export const { setCreditCard, setPayments, reset, setShippingMethod, setSelectedPaymentMethod, setPayrollMaxDeductions, setActivity: initializeActivity, setAddressActivity, setCustomerPurchaseOrder } = checkoutSlice.actions;
export const checkoutActions = checkoutSlice.actions;
export default checkoutSlice.reducer;
