import { useEffect, useState,useContext } from 'react';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { showErrorMsg } from '../../../helpers/ValidationHelper';
import rootAction from '../../../stateManagment/actions/rootAction';
import { makePriceRoundToTwoPlaces, makeProductShortDescription } from '../../../helpers/ConversionHelper';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import { skipToken } from '@reduxjs/toolkit/dist/query/index.js';
import LoadingWrapper from '../products/LoadingWrapper.js';
import { useChangeQuantityMutation, useGetCartDataQuery, useRemoveCartItemMutation } from '../../../services/cart.ts';
import { Popover, PopoverBody, Spinner } from 'reactstrap';
import { useAppDispatch } from '../../../stateManagment/reduxStore.js';
import { QuantityInput } from '../../views/checkout/Cart.js';
import _ from 'lodash';
import { ToggleContext } from '../../../ToggleProvider.js';
import SizingBoard from './SizingBoard.js';


let miniCartTimeout;

const MinicartContainer = ({ setIsCartChangeQuantityLoading, removeFromCart, setOpen, sizingCount }) => {
    const dispatch = useAppDispatch();
    const currentCart = useSelector((state) => state.cartReducer);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const [quantityError, setQuantityError] = useState({});

    // const handleChangeQuantity = async (productId, lineItemID, newLineQuantity, embroidery, embroideryPrice) => {
    //     if (newLineQuantity == 0) {
    //         showErrorMsg("Quantity cannot be below 1. Remove the item instead");
    //         return;
    //     }
    //     if (newLineQuantity > 200) {
    //         showErrorMsg("Quantity cannot be above 200");
    //         return;
    //     }
    //     try {
    //         const newCart = await changeQuantity({
    //             productId,
    //             lineItemID,
    //             newLineQuantity,
    //             embroideryString: JSON.stringify(embroidery),
    //             embroideryPrice,
    //         }).unwrap();
    //         dispatch(rootAction.cartActions.setCart(newCart));
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };

    const handleRemove = async (e, product) => {
        e.preventDefault();

        try {
            const newCart = await removeFromCart(product.lineItemId).unwrap();
            dispatch(rootAction.cartActions.setCart(newCart));
        }
        catch (err) {
            console.error(err);
        }
    };

    return (

        <div className="container cart d-flex flex-column overflow-hidden">
            <div className='minicart-content d-flex flex-column overflow-hidden'>
                <div className="minicat-header align-items-center">
                    <div className="col-12 p-0">
                        <h3 className="m-0">
                            <span className='mr-1'>{LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Shopping Bag", "lbl_hdr_shopping_bag")
                                :
                                "Shopping Bag"
                            }</span>
                            ({currentCart.totalCartItems})
                        </h3>
                    </div>
                    {isToggled?<>
                    <div className="col-12 p-0">
                       <SizingBoard/>
                    </div>
                    </>:<></>
                    }
                </div>
                <div className='product-summary has-custom-scrollbar'>
                    {currentCart?.products?.map((item, idx) => (
                        <div className='product-summary-item' key={item.lineItemId}>
                            <div className='product-line-item'>
                                <div className='product-line-item-details row'>
                                    <div className='col-4 item-image justify-content-center pr-0'>
                                        <Link to={`/${getLanguageCodeFromSession()}/product-detail/${encodeURIComponent(item.productId)}/${item.DepartmentId}/${_.kebabCase(item.productName)}`} onClick={() => setOpen(false)}>
                                            <img src={`${item.images[0].url}?sw=140&sh=165&sm=fit`} alt="image" className='product-image' />
                                        </Link>
                                    </div>
                                    <div className='item-attributes pl-3 col-8'>
                                        <div className='line-item-header'>
                                            <div className='line-item-name'>
                                                <Link to={`/${getLanguageCodeFromSession()}/product-detail/${encodeURIComponent(item.productId)}/${item.DepartmentId}/${_.kebabCase(item.productName)}`} onClick={() => setOpen(false)}>
                                                    {
                                                        makeProductShortDescription(item.productName, 80)
                                                    }
                                                </Link>
                                            </div>
                                            <div className='remove-line-item'>
                                                <Link to="#" onClick={(e) => { handleRemove(e, item) }} className='remove-product btn btn-link'>
                                                    Remove
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='line-item-style'>
                                            <span>
                                                Style #: {item.productId}
                                            </span>
                                        </div>
                                        <div className="mb-4">
                                            <p className="line-item-attributes">
                                                {
                                                    item.ColorLongName != undefined && item.ColorLongName != ""
                                                        ?
                                                        <><span>Color:</span> {item.ColorLongName}</>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </p>
                                            <p className="line-item-attributes">
                                                {
                                                    item.sizeShortName != undefined && item.sizeShortName != ""
                                                        ?
                                                        <><span>Size:</span> {item.sizeShortName.replace('_', '/')}</>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </p>
                                            {item.SelectedEmployee ?
                                                <p className="line-item-attributes">
                                                    <span>Employee:</span> {item.SelectedEmployee.replace(',', ' ')}
                                                </p> 
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className='row justify-content-between mr-0'>
                                            <div className='col-6 line-item-quantity'>
                                                <QuantityInput item={item} setIsCartChangeQuantityLoading={setIsCartChangeQuantityLoading} quantityError={quantityError} setQuantityError={setQuantityError}/>
                                            </div>
                                            {!isToggled?
                                            <>
                                            <div className='col-6 line-item-total-price text-right pr-0'>
                                                <div className='price'>
                                                    {item.discountedPrice != undefined && item.discountedPrice > 0 ?
                                                        <>
                                                            <del className='d-none'>{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(item.price / item.quantity)}</del>
                                                            <span className='pr-1 pr-md-0'>{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces((item.discountedPrice) - (item.embroideryPrice * item.quantity))}</span>
                                                        </>
                                                        :
                                                        <>
                                                            {GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(item.price / item.quantity)}
                                                        </>
                                                    }
                                                    {/* {(() => {
                                                            let itemSubTotal = (item.discountedPrice != undefined && item.discountedPrice > 0 ? item.discountedPrice : item.price);
                                                            return (
                                                                <span>{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(itemSubTotal)}</span>
                                                            );

                                                        })()} */}
                                                </div>
                                            </div>
                                            </>:<></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {item.embroidery?.length > 0 ?
                                <>
                                    <div className='embroidery-lineitem-select my-3 row custom-labels'>
                                        <div className='col main-label'>
                                            <div className='align-items-center d-flex font-weight-bold text-uppercase w-100'>
                                                <span className="d-md-inline-block d-none icon icon-embroideryNeedle mr-1"></span>
                                                Embroidery added
                                            </div>
                                        </div>
                                        {!isToggled&&
                                        <div className='col-auto embroidery-cart-price text-right main-label'>
                                            {/* {GetDefaultCurrencySymbol()}{item.EmbroideryPrice * item.Quantity} */}
                                            {GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(item.embroideryPrice * item.quantity)}
                                        </div>
                                        }
                                    </div>
                                    {/* <div className='d-none'>
                                    <VASTemplate
                                        VASData={JSON.parse(unminifyJSON(item.EmbroideryString))}
                                        VasSelectionIndex={0}
                                        ItemChecked={false}
                                    />
                                </div> */}

                                </> : <></>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className="minicart-footer">
                {!isToggled?<>
                <div className="row estimated-total">
                    <div className="col-8">
                        <p className="sub-total-label">Subtotal</p>
                    </div>
                    <div className="col-4">
                        <p className="text-right sub-total">{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(currentCart?.cartSubTotal ?? 0)}</p>
                    </div>
                </div></>:
                <>
                <div className="row estimated-total"></div>
                </>
                }
                <div className="row">
                    <div className="col-12 checkout-continue">
                        <Link to="/cart" onClick={() => setOpen(false)} className="btn btn-primary btn-block checkout-btn" role="button">
                            View Bag &amp; Checkout
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

const MiniCart = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { minicartDisabled } = useSelector((state) => state.commonReducer);
    const [isOpen, setIsOpen] = useState(false);
    const { isLoading: isMinicartLoading, isFetching: isMinicartFetching } = useGetCartDataQuery(null, {
        refetchOnMountOrArgChange: true
    });
    const [removeFromCart, { isLoading: isRemoveFromCartLoading }] = useRemoveCartItemMutation();
    const [isCartChangeQuantityLoading, setIsCartChangeQuantityLoading] = useState(false);
    const [allowedSizingItemCount, setAllowedSizingItemCount] = useState(0);
    const currentCart = useSelector((state) => state.cartReducer);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer); 

    const handleMouseLeave = () => {
        if (isMinicartFetching || isMinicartLoading) return;

        miniCartTimeout = setTimeout(() => {
            setIsOpen(false)
        }, 0);
    }

    const handleMouseEnter = () => {
        if (isMinicartFetching || isMinicartLoading || window.innerWidth < 992) return;

        clearTimeout(miniCartTimeout);
        if (!isOpen) {
            setIsOpen(!isOpen);
        }
    }

    const toggleMinicart = async () => {
        setIsOpen(false);
    };

    var [isMobile, setIsMobile] = useState(false);
    const handleWindowResize = () => {
        const resolution = window.innerWidth;
        if (resolution < 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize.bind(this));
    });

    useEffect(() => {
        if (pathname?.indexOf('checkout') !== -1 || pathname?.indexOf('order-review') !== -1) {
            dispatch(rootAction.commonAction.disableMiniCart(true));
        } else {
            dispatch(rootAction.commonAction.disableMiniCart(false));
        }
    }, [pathname]);

    useEffect(() => {        
        return () => {
            let accum = 0;
            if(userSizingTemplate&&userSizingTemplate.SizingTemplates&&userSizingTemplate.SizingTemplates.length>0){
                for(let i=0;i<userSizingTemplate?.SizingTemplates[0]?.Quotas?.length;i++){
                    accum+=userSizingTemplate?.SizingTemplates[0]?.Quotas[i].MaxQuantityPerOrder;
                }
            }
            setAllowedSizingItemCount(accum);
            clearTimeout(miniCartTimeout);
            setIsOpen(false);
        }
    }, []);

    return (
        <div id="miniCartContainer" className="minicart-total" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >

            <Link id="miniCartPopover" to="/cart" onClick={() => window.innerWidth > 992 ? toggleMinicart() : ''} className="minicart-link d-flex position-relative">
                <span className="icon icon-shoppingBagV2"></span>
                {isMinicartLoading || isMinicartFetching ?
                    <Spinner color="danger" size="sm" type='grow' className="position-absolute" style={{ top: -8, left: -8 }} />
                    :
                    (currentCart.totalCartItems > 0 ? <span className="minicart-quantity bubble">{currentCart.totalCartItems}</span> : null)
                }
            </Link>

            {currentCart?.totalCartItems > 0 && !minicartDisabled && !isMobile &&
                <Popover fade={false} className='popover_cover' placement="bottom-end" isOpen={isOpen} target="miniCartPopover" container="miniCartContainer" toggle={toggleMinicart} modifiers={{ offset: { offset: '-100,0' } }}>
                    <PopoverBody>
                        <LoadingWrapper isLoading={isMinicartFetching || isMinicartLoading || isRemoveFromCartLoading || isCartChangeQuantityLoading}>
                            <MinicartContainer setOpen={setIsOpen} cart={currentCart} removeFromCart={removeFromCart} sizingCount={allowedSizingItemCount} setIsCartChangeQuantityLoading={setIsCartChangeQuantityLoading} />
                        </LoadingWrapper>
                    </PopoverBody>
                </Popover>}

        </div>
    );
}

export default MiniCart;
