import { Outlet, ScrollRestoration } from "react-router-dom";
import ScrollToTop from "../shared/ScrollToTop";
import Seo from "../shared/Seo";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SizingBoard from "./SizingBoard";
import { useContext } from "react";
import { ToggleContext } from "../../../ToggleProvider";
import { useSelector } from "react-redux";

export default function RootLayout() {
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const loginUser = useSelector(state => state.userReducer.user);
    return (
        <>
            <Seo />
            {window.location.path !== '/login' && <Navbar />}
            {isToggled &&  loginUser.UserID &&
                <div style={{ width: "100%", padding: "10px" }}>
                    <SizingBoard />
                </div>
            }
            <Outlet />
            <Footer />
            <ScrollRestoration />
        </>
    );
}